import React from "react";
import styled from "@emotion/styled";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  compose
} from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";

import { MainLayout } from "../layouts";
import { LegalContainer } from "../components/pages/shared/LegalStyles";

const ColorBox = styled("div", { shouldForwardProp })(
  {
    margin: "auto",
    height: "60px",
    width: "60px",
    borderRadius: "6px",
    boxShadow: "0px 0px 0.5rem rgba(0, 0, 0, 0.5)"
  },
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position
  )
);

export default () => (
  <MainLayout>
    <LegalContainer>
      <div>
        <h1 id="corporatecolors">Corporate Colors</h1>
        <p>
          <em>Effective Date: November 24, 2019</em>
        </p>
        <p>
          AirPage encourages and supports other products and services that use
          and supplement our services. At the same time, we must protect our
          reputation and brand identity. So we ask that you follow these Brand
          Colors, which are intended to promote consistent use of our Brand
          Assets as described in our <a href="/brand-guidelines">Brand Guidelines.</a> This makes it easier for people to instantly recognize
          references to AirPage, and prevents consumer confusion. These
          guidelines also help protect AirPage’s intellectual property.
        </p>
        <p>
          If you don’t agree to these Brand Colors, you don’t have a right to,
          and shouldn’t, use the Brand Assets.
        </p>
        <h3>
          <strong>Primary Brand Colors</strong>
        </h3>
        <p>
          <strong>AirPage Blue</strong>
        </p>
        <ColorBox backgroundColor="#01A1FF" />
        Hex <em>#01A1FF</em>
        <p>
          <strong>White</strong>
        </p>
        <ColorBox backgroundColor="#ffffff" />
        Hex <em>#FFFFFF</em>
        {/* <h3>
          <strong>Secondary Brand Colors</strong>
        </h3>
        <ColorBox backgroundColor="#33ccff" />
        Hex <em>#33ccff</em> */}
        <p>
          To help us address future branding issues, we may modify these Brand
          Colors, without notice to you. We’ll post the most recent version on
          our website. You’re responsible for following any modified terms, so
          be sure to review these Brand Colors regularly.
        </p>
      </div>
    </LegalContainer>
  </MainLayout>
);
